
// BOOTSTRAP 4 VARIABLES

@import "components/bootstrap4/functions";
@import "components/bootstrap4/variables";
@import "components/bootstrap4/mixins";

// ArchitectUI Themes Variables

@import "themes/layout-variables";

// BOOTSTRAP 4 IMPORTS

@import "components/bootstrap4/root";
@import "components/bootstrap4/reboot";
@import "components/bootstrap4/type";
@import "components/bootstrap4/images";
@import "components/bootstrap4/code";
@import "components/bootstrap4/grid";
@import "components/bootstrap4/tables";
@import "components/bootstrap4/forms";
@import "components/bootstrap4/buttons";
@import "components/bootstrap4/transitions";
@import "components/bootstrap4/dropdown";
@import "components/bootstrap4/button-group";
@import "components/bootstrap4/input-group";
@import "components/bootstrap4/custom-forms";
@import "components/bootstrap4/nav";
@import "components/bootstrap4/navbar";
@import "components/bootstrap4/card";
@import "components/bootstrap4/breadcrumb";
@import "components/bootstrap4/pagination";
@import "components/bootstrap4/badge";
@import "components/bootstrap4/jumbotron";
@import "components/bootstrap4/alert";
@import "components/bootstrap4/progress";
@import "components/bootstrap4/media";
@import "components/bootstrap4/list-group";
@import "components/bootstrap4/close";
@import "components/bootstrap4/toasts";
@import "components/bootstrap4/modal";
@import "components/bootstrap4/tooltip";
@import "components/bootstrap4/popover";
@import "components/bootstrap4/carousel";
@import "components/bootstrap4/spinners";
@import "components/bootstrap4/utilities";
@import "components/bootstrap4/print";

// LAYOUT

@import "layout/layout";

// UTILS

@import "utils/helpers";
@import "utils/backgrounds";
@import "utils/animate";
@import "utils/comps-animations";

// ELEMENTS

@import "elements/buttons";
@import "elements/dropdown";
@import "elements/accordions";
@import "elements/modals";
@import "elements/timeline";
@import "elements/popovers-tooltips";

// DASHBOARD BOXES

@import "widgets/chart-boxes/chart-boxes";
@import "widgets/content-boxes/content-boxes";

// PAGES
@import "pages/userpages";

// COMPONENTS

// Perfect Scrollbar

@import "components/perfect-scrollbar/perfect-scrollbar";

// Hamburger button

@import "components/hamburgers/hamburgers";

// Icons

@import "components/icons/p7icons";

// Responsive

@import "layout/responsive/responsive-base";

// DEMO

@import "demo-ui/demo";

// FIX for Material DatePicker on Modal window
.cdk-overlay-container { position: fixed; z-index: 1100 !important; }

.main-page-link:hover { 
    color: #0056b3 !important;
    text-decoration: underline !important; 
    cursor: pointer !important;
}
    